import React from "react";
import Navbar from "./Navbar";
import { Grid, Typography, Box, Link } from "@mui/material";

const App = () => {
  return (
    <div className="App" style={{ overflowX: 'hidden' }}>
      <Navbar />
      <Hero />
      <HowItWorks />
      <Footer />
    </div>
  );
};

const Hero = () => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 5, md: 10, lg: 20, xl: 20 },
        paddingTop: 5,
        position: "relative",
      }}
    >
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justifyContent="center"
        width="100%"
      >
        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              variant="h3"
              component="h1"
              gutterBottom
              sx={{ fontWeight: 600 }}
            >
              Stay safe with real-time
              <span style={{ color: "#f44337" }}> danger alerts </span>
              and family tracking.
            </Typography>

            <Typography
              variant="h6"
              component="p"
              fontWeight={400}
              gutterBottom
              mt={2}
            >
              Protect your loved ones with instant danger alerts and live tracking, all in one app.
            </Typography>

            <Box marginTop={4} marginBottom={10}>
              <Typography
                variant="h6"
                component="p"
                fontWeight={400}
                gutterBottom
              >
                Coming soon to
              </Typography>

              <img
                src="/stores.png"
                alt="App Stores"
                loading="lazy"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  display: "inline",
                  margin: "0 auto",
                }}
              />
            </Box>
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Ensures the container is always centered
              alignItems: "center",
              width: "100%", // Full width of the parent container
            }}
          >
            <Box
              sx={{
                width: { xs: "60%", sm: "50%", md: "50%" }, // Dynamically adjust width based on screen size
                aspectRatio: "9/20",
                position: "relative",
              }}
            >
              {/* Image 1 */}
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%) rotate(-30deg) translateX(-10%)",
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                }}
              >
                <img
                  src="/create_join_location_page.jpg"
                  alt="Location Page"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                />
              </Box>

              {/* Image 2 */}
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%) rotate(-10deg)",
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
              >
                <img
                  src="/circles.jpg"
                  alt="Circles"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                />
              </Box>

              {/* Image 3 */}
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%) rotate(10deg) translateX(10%)",
                  width: "100%",
                  height: "100%",
                  zIndex: 3,
                }}
              >
                <img
                  src="/alert_history.jpg"
                  alt="Alert History"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                />
              </Box>

              {/* Image 4 */}
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%) rotate(30deg) translateX(20%)",
                  width: "100%",
                  height: "100%",
                  zIndex: 4,
                }}
              >
                <img
                  src="/location_page.jpg"
                  alt="Create Join Location"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "20px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  );
};


const HowItWorks = () => {
  return (
    <Box sx={{ px: { xs: 2, sm: 4, md: 6, lg: 8 }, py: 30 }}>
      <Typography
        color="primary"
        variant="h3"
        fontWeight={600}
        textAlign="center"
        gutterBottom
      >
        How it Works
      </Typography>
      <Section
        title={"Monitor"}
        heading={"Stay updated on nearby dangers in real-time"}
        subheading={
          "Get instant alerts about safety concerns in your area, helping you make informed decisions about your surroundings."
        }
        imagePath={"/location_page.jpg"}
      />
      <Section
        title={"Connect"}
        heading={"Track the locations of friends and family"}
        subheading={
          "Keep your loved ones close by sharing real-time location data within your trusted circle, ensuring peace of mind."
        }
        imagePath={"/create_join_location_page.jpg"}
        layout="right"
      />
      <Section
        title={"Alert"}
        heading={"Receive immediate notifications when someone needs help"}
        subheading={
          "Get instant notifications when someone in your circle is in danger, enabling quick response in critical situations."
        }
        imagePath={"/alert_history.jpg"}
        layout="left"
      />
    </Box>
  );
};

const Section = ({
  title,
  heading,
  subheading,
  imagePath,
  layout = "left",
}) => {
  return (
    <Box sx={{ py: 5 }}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        direction={{ xs: "column", md: "row" }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              md: layout === "left" ? "flex-end" : "flex-start",
            },
            order: { xs: 2, md: layout === "left" ? 1 : 2 },
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "500px", // Set a reasonable max width
              height: "auto",
              borderRadius: "16px", // Add rounded corners
              overflow: "hidden", // Ensure the image respects the border radius
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: add subtle shadow
            }}
          >
            <img
              src={imagePath}
              alt="SALO Feature"
              loading="lazy"
              style={{
                width: "100%",
                height: "auto",
                display: "block",
                objectFit: "cover",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ order: { xs: 1, md: layout === "left" ? 2 : 1 } }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="left"
            color="primary"
            sx={{ fontWeight: "600" }}
          >
            {title}
          </Typography>
          <Typography
            variant="h5"
            component="p"
            fontWeight={600}
            gutterBottom
            align="left"
          >
            {heading}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            fontWeight={400}
            gutterBottom
            color="#808080"
            align="left"
          >
            {subheading}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};


const Footer = () => {
  return (
    <Box
      sx={{
        py: 4,
        px: 2,
        backgroundColor: "#ffccd1",
        color: "#000000",
        textAlign: "center",
      }}
    >
      <Typography variant="body1" gutterBottom>
        &copy; {new Date().getFullYear()} Salo. All rights reserved.
      </Typography>
      <Typography variant="body2" gutterBottom>
        <Link href="mailto:mad64@mail.aub.edu" color="inherit">
          Contact
        </Link>
      </Typography>
    </Box>
  );
};
export default App;
